.mesasArea {
    .area{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 1rem;
        
        .titulo {
            width: 100%;
        }

        .mesa {
            min-width: 10rem;
            min-height: 8rem;
            margin: 1rem;

            display: flex;
            align-items: center;
            justify-content: center;
            
            div {
                flex: none;
                font-size: 3rem !important;
            }
        }
        .mesa:hover {
            // background-color: var(--bs-purple) !important;
            outline: 0.2rem solid var(--bs-primary);
            cursor: pointer;
        }
    }
}

.alert{
    animation-name: animAlert !important;
    animation-duration: 1s !important;
    animation-iteration-count: infinite !important;
    animation-timing-function: ease-in-out !important;
    animation-fill-mode: both !important;

    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes animAlert {
	from {
		background-color: var(--bs-warning);
        width: 100%;
        height: 100%;

	}
	to {
		background-color: var(--bs-primary);
        width: 100%;
        height: 100%;
    }
}