.rodape {
	background-color: #f7f7f7;
	z-index: 99;
	height: 9%;
	width: 100vw;
	
	position: fixed;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
}