* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.horizontalCenter {
	// display: flex;
	// flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	height: auto !important;

	form {
		label {
			font-size: 1.1rem;
		}
		width: 50%;
	}

	@media(max-width: 720px) {
		form {
			width: 100%;
		}
	}
}

.verticalCenter {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;

	form {
		label {
			font-size: 1.1rem;
		}
		width: 50%;
	}

	@media(max-width: 720px) {
		form {
			width: 100%;
		}
	}
}

.verticalCenterFull {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;

	form {
		label {
			font-size: 1.1rem;
		}
		width: 50%;
	}

	@media(max-width: 720px) {
		form {
			width: 100%;
		}
	}
}

.logoLoginTo1 {
	animation-name: animEntrando;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-fill-mode: both;
}

.logoLoginTo2 {
	animation-name: animSaindo;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-fill-mode: both;
}

.rotacao {
	animation-name: animRotacao;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
}

@keyframes animEntrando {
	from {
		-webkit-transform: rotate(0deg) scale(0.75) skew(0deg) translate(-1000px);
		visibility: hidden;
		opacity: 0;
	}
	to {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(2px) ;
		visibility: visible;
		opacity: 1;
	}
}

@keyframes animSaindo {
	0% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(2px);
		visibility: visible;
		opacity: 1;
	}
	99% {
		-webkit-transform: rotate(0deg) scale(0.75) skew(0deg) translate(1500px) ;
		visibility: hidden;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(-1502px) ;
	}
}

@keyframes animRotacao {
	from {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(0);
	}
	to {
		-webkit-transform: rotate(-359deg) scale(1) skew(0deg) translate(0) ;
	}
}

.tabela {
	// table-layout: fixed;
	width: 100%;
    border-spacing: 0;
	user-select: none;

	thead{
        z-index: 98;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
		// outline: 1px solid #dee2e6;

		th{
			background-color: var(--bs-white);
			outline: 1px solid #dee2e6;
		}
    }

	tbody{
		tr{
			td{
				// min-width: 7rem;
				word-wrap: break-word;
			}
		}
	}
}

.tabelaWrapper {
	display: block;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.tabelaHorizontal {
	// table-layout: fixed;
	width: 100%;
    border-spacing: 0;
	user-select: none;

	thead{
        z-index: 98;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;

		th{
			background-color: var(--bs-white);
			outline: 1px solid #dee2e6;
		}
    }

	tbody{
		display: flex;
		flex-direction: row;

		tr{
			display: flex;
			flex-direction: column;
			width: 100%;

			td{
				// min-width: 7rem;
				word-wrap: break-word;
			}
		}
	}
}

@media(max-width: 1080px) {
	html {
		font-size: 93.75%;
	}
}

@media(max-width: 720px) {
	html {
		font-size: 87.5%;
	}
}

.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

.imgItem {
	animation-name: animProduto;
	animation-duration: 20s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
}

@keyframes animProduto {
	0% {
        background-position-y: 15%;
	}
	50% {
        background-position-y: 85%;

	}
    99% {
        background-position-y: 15%;
    }
}