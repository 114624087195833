.loading {
	animation-name: riding;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
}

@keyframes pulsing {
	from {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(0px);
		opacity: 1;
	}
	to {
		-webkit-transform: rotate(0deg) scale(0.4) skew(0deg) translate(0px) ;
		opacity: 0.4;
	}
}

@keyframes riding {
	0% {
		-webkit-transform: rotate(0deg) scale(0.8) skew(-20deg) translate(-150px);
        opacity: 0.5;
	}
    5% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(-90px);
        opacity: 1;
	}
    10% {
		-webkit-transform: rotate(1deg) scale(1) skew(0deg) translate(-80px);
        opacity: 1;
	}
    15% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(-70px);
        opacity: 1;
	}
    20% {
		-webkit-transform: rotate(-1deg) scale(1) skew(0deg) translate(-60px);
        opacity: 1;
	}
    25% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(-50px);
        opacity: 1;
	}
    30% {
		-webkit-transform: rotate(-1deg) scale(1) skew(0deg) translate(-40px);
        opacity: 1;
	}
    35% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(-30px);
        opacity: 1;
	}
    40% {
		-webkit-transform: rotate(1deg) scale(1) skew(0deg) translate(-20px);
        opacity: 1;
	}
    45% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(-10px);
        opacity: 1;
	}
    50% {
		-webkit-transform: rotate(-1deg) scale(1) skew(0deg) translate(0px);
        opacity: 1;
	}
    55% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(5px);
        opacity: 1;
	}
    60% {
		-webkit-transform: rotate(1deg) scale(1) skew(0deg) translate(10px);
        opacity: 1;
	}
    65% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(20px);
        opacity: 1;
	}
    70% {
		-webkit-transform: rotate(-1deg) scale(1) skew(0deg) translate(30px);
        opacity: 1;
	}
    75% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(40px);
        opacity: 1;
	}
    80% {
		-webkit-transform: rotate(1deg) scale(1) skew(0deg) translate(50px);
        opacity: 1;
	}
    85% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(60px);
        opacity: 1;
	}
    90% {
		-webkit-transform: rotate(-1deg) scale(1) skew(0deg) translate(70px);
        opacity: 1;
	}
    95% {
		-webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(80px);
        opacity: 1;
	}
	100% {
		-webkit-transform: rotate(0deg) scale(0.9) skew(-10deg) translate(150px);
        opacity: 0.5;
	}
}