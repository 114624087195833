.categorias {
	flex-wrap: wrap;
	flex-direction: column;
	user-select: none;

	@media(max-width: 575px) {
		flex-direction: row;
		flex-wrap: nowrap !important;
		overflow-x: scroll;
		white-space: nowrap;

		padding-bottom: 1rem;
	}
}

.conteudo {
	user-select: none;
	height: calc(100vh - 15rem);
	overflow-y: scroll;

	// @media(max-height: 575px) { 
	// 	height: calc(100vh - 13rem);
	// 	// height: 54vh;
	// }

	@media(max-width: 575px) { 
		height: calc(100vh - 19rem);
		// height: 66vh;
	}
}

.customCheckbox {
	margin-right: 0.5rem;

	input:disabled {
		opacity: 1 !important;
	};
}